<template>
  <div>
    <title>SIM INVENTORY ~ ADD ITEM CATEGORY DATA</title>
    <section class="content-header">
      <h1>
        Add Item Category Data
        <br />
        <h4>Please enter the item category master data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Master Item Category</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Item Category Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Category </label>
                  <input
                    type="text"
                    v-model="isidata.kategoribarang"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Subcategory</label>
                  <input
                    type="text"
                    v-model="isidata.subkategori"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notes"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Type</label>
                  <input
                    type="text"
                    v-model="isidata.tipe"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                ><i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/kategori-barang">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      isidata: {
        kategoribarang: "",
        subkategori: "",
        notes: "",
        tipe: ""
      }
    };
  },
  methods: {
    async saveData() {
      this.loading = true;
      var kategori = this.isidata.kategoribarang;
      var tipe = this.isidata.tipe;
      var subk = this.isidata.subkategori;
      var catatan = this.isidata.notes;
      if (kategori == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item Category can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tipe == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Type can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (subk == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Subcategory can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatan == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kategori: this.isidata.kategoribarang,
          sub_kategori: this.isidata.subkategori,
          notes: this.isidata.notes,
          tipe: this.isidata.tipe,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPISaveData = "http://26.183.23.191/inventory/backend/inventory/api/kategori_barang/savekategori_barang";
        const urlAPISaveData =
          this.$apiurl + "kategori_barang/savekategori_barang";
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPISaveData, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.isidata.kategoribarang = "";
              this.isidata.tipe = "";
              this.loading = false;
              this.$router.push({ name: "kategoribarang" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "kategoribarang" });
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
